<script>
export default {
  props: {
    interceptor: {
      type: Boolean,
      required: true
    }
  },

  mounted() {
    this.$emit('is-valid', this.interceptor);
  },

  watch: {
    interceptor(newVal) {
      this.$emit('is-valid', newVal);
    }
  }
};
</script>
